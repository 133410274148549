import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next/hooks';

import Button from '../../components/Button/Button';

import { useStore } from '../../hook-store/store';


import { loginPageLink, authSignupPageLink, BASE_URL } from '../../App';
import './NotPageFound.css';


const LoginRequired = props => {
  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // const { userData, isAuth } = store.uiStore;
  // console.log('store in NotPageFound.js', store);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  // console.log(currentUrl);



  return (
    <Fragment>
        <div>
          <div>
            {t('userPage.text05', 'Please login first to change user settings')}
          </div>
          <a className="notPageFound__linkButton" href={loginPageLink} >
            <Button
                  mode="raised" type="submit" design="success"
                  // disabled={!props.replyInput || props.commentLoading}
            >
              {/* Login */}
              {t('general.text11')}
            </Button>
          </a>
        </div>
    </Fragment>
  );
}

export default LoginRequired;