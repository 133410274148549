import React from 'react';
import ReactDOM from 'react-dom';

import './Backdrop.css';
import classes from './Backdrop.module.css';

const Backdrop = props => {
  const { zIndex, backgroundColor } = props;

  let backdropStyle = null;

  if (zIndex) {
    backdropStyle = {
      zIndex: `${zIndex}`
    }
  }

  if (backgroundColor) {
    backdropStyle = {
      ...backdropStyle,
      backgroundColor: backgroundColor,
    }
  }

  return (
      <div
        // className={['backdrop', props.open ? 'open' : ''].join(' ')}
        className={classes.backdrop}
        style={backdropStyle}
        onClick={props.onClick}
      />
  );

  // return (
  //   ReactDOM.createPortal(
  //     <div
  //       className={['backdrop', props.open ? 'open' : ''].join(' ')}
  //       style={backdropStyle}
  //       onClick={props.onClick}
  //     />,
  //     document.getElementById('backdrop-root')
  //   )
  // );
}
export default Backdrop;
