import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SHOW_NOTIFICATION: (state, payload) => {
      const notifyContent = {
        status: payload.status,
        title: payload.title,
        message: payload.message,
      };
      return { 
        ...state,
        uiStore: {
          ...state.uiStore,
          notification: notifyContent, 
        }
        // i18n: state.i18next,
      };
    },
    CLEAR_NOTIFICATION: (state, payload) => {
      // const notifyContent = {
      //   status: null,
      //   title: null,
      //   message: null,
      // };
      return { 
        ...state,
        uiStore: {
          ...state.uiStore, 
          notification: null,
        }
        // notification: notifyContent,
        // i18n: state.i18next,
       };
    },
    // SET_USERSDATA: (state, payload) => {
    //   return {
    //     ...state,
    //     usersData: payload,
    //   }
    // },
    // SET_GROUP_USERSDATA: (state, payload) => {
    //   return {
    //     ...state,
    //     groupUsersData: payload,
    //   }
    // },
    // SET_GROUPMEMBER_IMAGEURLS: (state, payload) => {
    //   return {
    //     ...state,
    //     groupMemberImageUrls: payload,
    //   }
    // },
    SET_USERDATA: (state, payload) => {
      return {
        uiStore : {
          ...state.uiStore,
          userData: payload,
        }
      }
    },
    // SET_USER_ADELEMENTS: (state, payload) => {
    //   return {
    //     ...state,
    //     userAdElements: payload,
    //   }
    // },
    // SET_SELECTED_ADELEMENT: (state, payload) => {
    //   return {
    //     ...state,
    //     selectedAdElement: payload,
    //   }
    // },
    // SET_LISTFOR_SEARCHAD: (state, payload) => {
    //   return {
    //     ...state,
    //     listForSearchAd: payload,
    //   }
    // },
    SET_LANGUAGE_NAME_LIST: (state, payload) => {
      return {
        uiStore : {
          ...state.uiStore,
          languageNameList: payload,
        }
      }
    },
    SET_ISAUTH: (state, payload) => {
      return {
        // ...state,
        uiStore : {
          ...state.uiStore,
          isAuth: payload,
        }
      }
    },
    SET_GLOADING: (state, payload) => {
      return { 
        // ...state,
        uiStore : {
          ...state.uiStore,
          gLoading: payload, 
        }
      };
    },
    SET_USERNAMEDATA: (state, payload) => {
      return { 
        // ...state,
        uiStore : {
          ...state.uiStore,
          userNameData: payload, 
        }
      };
    },
    SET_SHOWTOGGLENAV: (state, payload) => {
      return {
        // ...state,
        uiStore : {
          ...state.uiStore,
          showToggleNav: payload,
        }
      }
    },
    // SET_I18NEXT: (state, payload) => {
    //   return { 
    //     notification: state.notification,
    //     i18n: payload,
    //   }
    // }
  };

  initStore(actions, {
    uiStore: {
      notification: null,
      // usersData: [],
      // groupUsersData: [],
      // groupMemberImageUrls: [],
      userData: null,
      userNameData: null,
      // userAdElements: [],
      // selectedAdElement: null,
      // listForSearchAd: [],
      languageNameList: [],
  
      isAuth: false,
      gLoading: false,
      showToggleNav: false,
    }
    // isUiLoading: false,

    // notification: {
    //   status: null,
    //   title: null,
    //   message: null,
    // },
    // i18n : undefined
  });
};

export default configureStore;
