import React from 'react';
import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next/hooks';
// import { useHistory } from 'react-router-dom'; // version 5.2.0
// import { useNavigate } from 'react-router-dom';

import Loader from '../Loader/Loader';
import { useStore } from '../../hook-store/store';

import { BACKEND_URL } from '../../App';
// const url = 'http://cas.example.org:8500';
// const casUrl ='http://localhost:8500';

// const casFrontUrl = 'http://localhost:8501';
// const casFrontUrl = 'https://authsso.spaceeight.net';

// const targetUrl = casFrontUrl + `/?fromUrl=${window.location.origin}`;
// const loginURL = casFrontUrl + `/login?fromUrl=${encodeURIComponent(window.location.origin)}`;
// const signupURL = casFrontUrl + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`
// const nodeUrl = process.env.REACT_APP_DEV_NODEURL;

const DisableTfa = props => {
  console.log(props);

  const [t] = useTranslation('translation');

  // const history = useHistory();
  // const navigate = useNavigate();

  const [store, dispatch] = useStore();
  // const userData = store.userData;
  // console.log(store);

  const [result, setResult] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fbDisable2FAuthHandler = async () => {
    try {
      setIsLoading(true);

      const result = await fetch(BACKEND_URL + '/two-factor-auth/disable-tfa', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        // body: JSON.stringify({
        //   tfaBuffer: tfaBuffer,
        // })
      });
  
      const resData = await result.json();
  
      console.log(resData, result);

      localStorage.removeItem('set2fa');
      setResult(`${t('2FA.text22', 'Disable Two-Factor Authentication Success')}`);
      setIsLoading(false);

      setTimeout(() => {
        window.location.reload();
      },1000*5);

    } catch(err) {
      console.log(err);
      setResult(`${t('2FA.text23', 'Disable Two-Factor Authentication Failed')}`);
      setIsLoading(false);
    }

  };

  return (
    <Fragment>

        <div>
          <button onClick={fbDisable2FAuthHandler}>
            {t('2FA.text21', 'Disable Two-Factor Authentication')}
          </button>
        </div>

        {isLoading && (
          <div><Loader /></div>
        )}

        <div>{result}</div>

    </Fragment>
  )
}

export default DisableTfa;