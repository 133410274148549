import React, { Fragment } from 'react';

// import Footer from '../Navigation/Footer/Footer';
import MainNav from '../Navigation/MainNav/MainNav';
// import MainNav from '../Navigation-old/MainNavigation/MainNavigation';
import Notification from '../UI/notification';
import { useStore } from '../../hook-store/store';

import './Layout.css';
import classes from './Layout.module.css';

const layout = props => {

  const [state, dispatch] = useStore();
  console.log(state);

  const notification = state.notification;

  return (
    <Fragment>
          <div className={classes.AppContainer}>
      <MainNav />

      <main className={classes.main}>{props.children}</main>
      
      {/* <Footer /> */}
      {/* <Language />
      <Footer /> */}

      {notification && (
        <div>
          <Notification 
            status={notification.status}
            title={notification.title}
            message={notification.message}
          />
        </div>
      )}
    </div>

      {/* <header className="main-header">{props.header}</header>
        {props.mobileNav}
      <main className="content">{props.children}</main>
      
      {notification && (
        <Notification
          status={notification.status}  // 'success' 'error' 'pending'
          title={notification.title}
          message={notification.message}
        />
      )} */}
    </Fragment>
  );
};

export default layout;
