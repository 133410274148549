import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next/hooks';
import i18n from '../../i18n';

import Backdrop from '../../components/Backdrop/Backdrop';
import Input from '../../components/Form/Input/Input';
import Button from '../../components/Button/Button';
import DarkModeToggle from '../../components/DarkModeToggle/DarkModeToggle';
import Loader from '../../components/Loader/Loader';
import SmallModal from '../../components/Modal/SmallModal';
import Modal from '../../components/Modal/Modal';

import { useStore } from '../../hook-store/store';
import {
  updateUserNameDataImage,
  deleteUserNameDataImage,
} from '../../util/user-name-data/user-name-data-util';

import { BACKEND_URL} from '../../App'

import './UserInfoImageUpdate.css';
// import classes from './UserInfo.module.css';

import SampleImage from '../../images/person-icon-50.jpg';

const UserInfoImageUpdate = props => {
  // console.log('userinfoImageUpdate-props', props);

  const [t] = useTranslation('translation');

  const hiddenFileInput = useRef(null);

  const [store, dispatch] = useStore();
  const { 
    userData, 
    userNameData 
  } = store.uiStore;
  // console.log('store UserInfo.js', store);

  const [isImageUpdate, setIsImageUpdate] = useState(false);
  const [selectedImageFiles, setSelectedImageFiles] = useState([]);
  const [showDeleteImageConfirm, setShowDeleteImageConfirm] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  // const [selectedLanguageName, setSelectedLanguageName] = useState('');
  const [error, setError] = useState('');


 
  const userImageSelectHandler = (event) => {
    console.log(event.target.files);
    setSelectedImageFiles(event.target.files);
  }

  const handleInputClick = (event, inputNum) => {
    hiddenFileInput.current.click();
  };

  const updateUserImageHandler = async () => {
    try {
      setIsLoading(true);

      const resData = await updateUserNameDataImage(
        BACKEND_URL,
        localStorage.getItem('token'),
        selectedImageFiles,
      );

      console.log(resData, resData.data);
      if (resData?.data?.data) {
        dispatch('SET_USERNAMEDATA', resData.data.data);
        setIsImageUpdate(false);
      }

      setIsLoading(false);
    } catch(err) {
      console.log(err);
      // throw err;
      setIsLoading(false);
    }
  }

  const deleteUserImageHandler = async () => {
    try {
      setIsLoading(true);

      const resData = await deleteUserNameDataImage(
        BACKEND_URL,
        localStorage.getItem('token'),
      );

      console.log(resData, resData.data);
      if (resData?.data) {
        dispatch('SET_USERNAMEDATA', resData.data);
        setShowDeleteImageConfirm(false);
        // setIsImageUpdate(false);
      }

      setIsLoading(false);
    } catch(err) {
      console.log(err);
      // throw err;
      setIsLoading(false);
    }
  }



  let userImageUpdateBody;


    userImageUpdateBody = (
      <div>
        <div>User Image</div>
        <div className='userInfoUserImageContainer'>
          {userNameData?.imageUrl && (
            <img className='userInfoUserImage'
              src={userNameData.imageUrl} 
              // style={{height:"50px", width:"50px", objectFit:"cover"}}
            />
          )}
          {!userNameData?.imageUrl && (
            <img className='userInfoUserImage'
              src={SampleImage} 
              // style={{height:"50px", width:"50px", objectFit:"cover"}}
            />
          )}

          <Button design="" mode="flat" type="submit"
            onClick={() => { setIsImageUpdate(true)}}
          >
            (change image)
          </Button>

          {userNameData?.imageUrl && (
            <Button design="danger" mode="flat" type="submit"
              disabled={isLoading}
              onClick={() => { 
                setShowDeleteImageConfirm(true);
              }}
            >
              (delete image)
            </Button>
          )}
        </div>
      </div>
    );
  


  return (
    <Fragment>
        <div>
          {userImageUpdateBody}
        </div>
        {isLoading && (
          <Loader />
        )}
        {showDeleteImageConfirm && (
          <div>
            <Backdrop 
              zIndex={'190'}
              // backgroundColor={'rgba(0, 0, 0, 0.1)'}
              onClick={() => { 
                setShowDeleteImageConfirm(false); 
              }}
            />
            <Modal
              onCancelModal={() => {
                setShowDeleteImageConfirm(false);
              }}
              onAcceptModal={() => {
                deleteUserImageHandler();
              }}
              acceptEnabled={true}
              isLoading={isLoading}
              hideButtons={false}
            >
              <div className='userInfoUserImageUpdateRow'>
                Delete image?
              </div>
              {/* {isLoading && (
                <Loader />
              )} */}
            </Modal>
          </div>
        )}

        {isImageUpdate && (
          <div>
            <Backdrop 
              zIndex={'190'}
              // backgroundColor={'rgba(0, 0, 0, 0.1)'}
              onClick={() => { 
                setIsImageUpdate(false); 
              }}
            />
            <Modal
              onCancelModal={() => {
                setIsImageUpdate(false);
              }}
              // onAcceptModal={() => {
              //   deleteUserImageHandler();
              // }}
              acceptEnabled={true}
              isLoading={isLoading}
              hideButtons={true}
            >
              <div>
                <div className='userInfoUserImageUpdateRow'>
                  <div className='userInfoUserImageUpdateTitle'>
                    <strong>
                      Change User Image
                    </strong>
                  </div>
                </div>
                <div className='userInfoUserImageUpdateRow'>
                  <button
                    //   disabled={gLoading}
                    className="userInfoUserSelectButton"
                    onClick={(event) => {
                      handleInputClick(event);
                    }}
                  >
                    Select image
                  </button>
                  <input
                    id="fileinput"
                    type="file"
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      userImageSelectHandler(event);
                    }}
                    accept="image/png,image/jpeg,image/webp"
                  />
                  <div>
                    accept image file type (jpeg, png, webp), file size should be less than 1MB
                  </div>
                </div>

                  {selectedImageFiles?.length > 0 && (
                    <div className='userInfoUserImageUpdateRow'>
                      <img 
                        style={{height:"100px", width:"100px", objectFit:"cover"}}
                        src={URL.createObjectURL(selectedImageFiles[0])}
                      />
                    </div>
                  )}

                  <div className='userInfoUserImageUpdateRow'>
                    <div className='userInfoUserImageUpdateButtons'>
                      <Button design="" mode="flat" type="submit"
                        disabled={isLoading}
                        onClick={() => {
                          setIsImageUpdate(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button design="" mode="raised" type="submit"
                        disabled={isLoading || selectedImageFiles.length === 0 || 
                          selectedImageFiles[0].size > 10**6
                        }
                        onClick={() => {
                          updateUserImageHandler()
                        }}
                      >
                        Change image
                      </Button>

                    </div>
                </div>
              </div>
            </Modal>
          </div>
        )}
    </Fragment>
  );
}

export default UserInfoImageUpdate;