import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next/hooks';
import i18n from '../../i18n';
import Input from '../../components/Form/Input/Input';
import Button from '../../components/Button/Button';
import DarkModeToggle from '../../components/DarkModeToggle/DarkModeToggle';
import UserInfoImageUpdate from './UserInfoImageUpdate';
import UserInfoNameUpdate from './UserInfoNameUpdate';
import Loader from '../../components/Loader/Loader';
import LanguageList from '../../translation/LanguageList';
import { resources } from '../../translation/translation';
import SmallModal from '../../components/Modal/SmallModal';
import TransBackdrop from '../../components/Backdrop/TransBackdrop';

import DisableTfa from '../../components/TwoFactorAuth/DisableTfa';
import TwoFactorAuth from '../../components/TwoFactorAuth/TwoFactorAuth';
import {
  getUserLocation,
} from '../../util/user';
import { useStore } from '../../hook-store/store';
import { 
  updateUserNameDataName,
  updateUserNameDataImage,
} from '../../util/user-name-data/user-name-data-util';

import { BACKEND_URL} from '../../App'

// import './UserInfo.css';
import classes from './UserInfo.module.css';

import SampleImage from '../../components/Image/person-icon-50.jpg';

const UserInfo = props => {
  // console.log('userinfo-props', props);

  const ls2faState = localStorage.getItem("set2fa");
  const selectedLanguage = resources[i18n.language].translation.LANGUAGE;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { 
    userData, 
    userNameData 
  } = store.uiStore;
  // console.log('store UserInfo.js', store);

  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const [userImageUrl, setUserImageUrl] = useState('');
  const [userCreationDate, setUserCreationDate] = useState('');
  const [userDescription, setUserDescription] = useState('');
  const [descriptionInput, setDescriptionInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [darkMode, setDarkMode] = useState();
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  // const [selectedLanguageName, setSelectedLanguageName] = useState('');
  const [error, setError] = useState('');

  // useEffect(() => {
  //   console.log('in useeffect in userinofo.js');
  //   setIsLoading(true);

  //   getUserData(BACKEND_URL, localStorage.getItem('token'))
  //     .then(result => {
  //       console.log(result);
  //       userInfoSet(result.userData);

  //       setIsLoading(false);

  //       props.userInfoAction(result.userData);
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       // catchError(err);
  //     })
  // }, [userName, userImageUrl, userDescription]);

  useEffect(() => {
    if (userData) {
      userInfoSet(userData);
    }
  },[userData]);

  useEffect(() => {
    updateLocationHandler();
    // getFollowedUserListHandler();
  }, []);

  const userInfoSet = (userInfo) => {
    setUserId(userInfo._id);
    setUserName(userInfo.name);
    // setNameInput(userInfo.name);

    setUserImageUrl(userInfo.imageUrl);
    setUserCreationDate(userInfo.createdAt);

    setUserDescription(userInfo.description);
    setDescriptionInput(userInfo.description);
  }



  const catchError = error => {
    setError(error);
  };



  const updateLocationHandler = () => {
    getUserLocation()
      .then(result => {
        console.log(result);
        // const locationData = result.data;

        // return updateUserLocation('', locationData, BACKEND_URL, props.token)
        //   .then(res => {
        //     console.log(res)
        //   })
        //   .catch(err => {
        //     console.log(err);
        //     catchError(err);
        //   });

      })
      .catch(err => {
        console.log(err);
        catchError(err);
      });
  }



  // const updateUserDescriptionHandler = (description) => {
  //   setIsLoading(true);
  //   updateUserDescription(description, BACKEND_URL, props.token)
  //     .then(result => {
  //       console.log(result);
  //       setUserDescription(result.data.data.user.description);
  //       // localStorage.setItem('name', result.data.data.user.);

  //       setIsDescriptionUpdate(false);
  //       setIsLoading(false);
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       setIsDescriptionUpdate(false);
  //       setIsLoading(false);
  //       catchError(err);
  //     })
  // }










  const showLanguageModalHandler = () => {
    setShowLanguageModal(!showLanguageModal);
  };

  // const getLanguageNameHandler = (name) => {
  //   setSelectedLanguageName(name);
  // }

  const setDarkModeHandler = (darkModeInput) => {
    setDarkMode(darkModeInput)
  };







 

  const languageModalBody = (
    <div>
    <TransBackdrop onClick={showLanguageModalHandler} />
    <SmallModal style="languageModal">
      <div className="userInfo__closeModalButton"
      onClick={showLanguageModalHandler}
      >
        X
      </div>
      <LanguageList 
        showLanguageModalHandler={showLanguageModalHandler}
        // getLanguageNameHandler={getLanguageNameHandler}
        // i18n={props.i18n}
      />
    </SmallModal>
  </div>
  );


  let body;

  if (isLoading) {
    body = (
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <Loader />
      </div>
    );
  } else {
    body = (
      <div className={classes.userPage}>
        {userData && (
          <div>
            <div className={classes.userPageItem}>
              <UserInfoNameUpdate 
              />
            </div>
            <div className={classes.userPageItem}>
              email: <strong>{userData.email}</strong>
            </div>

            <div className={classes.userPageItem}>
              <UserInfoImageUpdate />
            </div>

            {/* <div className={classes.userPageItem}>
              login time:{' '}
              <strong>
                {userData && new Date(userData.iat * 1000).toLocaleString()}
              </strong>
            </div> */}

            <div className={classes.userPageItem}>
              <div className={classes.userInfo__infoContent}>
                <span>
                  {t('userInfo.text11', 'Language')}:
                </span>
                <span className={classes.userInfoLanguageName}>
                  <strong>{selectedLanguage}</strong>
                </span>

                <div className="">
                  <Button
                    mode="flat" type="submit"
                    // disabled={descriptionInput ? descriptionInput.length > 300 : null}
                    onClick={showLanguageModalHandler}
                  >
                    ({t('userInfo.text12', '(Change)')})
                  </Button>
                </div>

                {showLanguageModal ? languageModalBody : null}
              </div>
            </div>

            <div className={classes.userPageItem}>
              {t("userPage.text02", "Two-Factor Authentication")}:{" "}
              <strong>
                {ls2faState
                  ? `${t("userPage.text03", "Enabled")}`
                  : `${t("userPage.text04", "Disabled")}`
                }
              </strong>
            </div>

            {ls2faState && <DisableTfa />}
            {!ls2faState && <TwoFactorAuth />}
          </div>
        )}
      </div>
    );
  }

  return (
    <Fragment>
        <div>
          {body}
        </div>
    </Fragment>
  );
}

export default UserInfo;