import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next/hooks';

import Button from '../../components/Button/Button';
import LoginRequired from './LoginRequired';
import { useStore } from '../../hook-store/store';


import { loginPageLink, authSignupPageLink, BASE_URL } from '../../App';
import './NotPageFound.css';


const NotPageFound = props => {
  console.log('NotPageFound-props', props);
  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { userData } = store.uiStore;
  // console.log('store in NotPageFound.js', store);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const currentUrl = new URL(window.location.href);
  const queryParams = currentUrl.searchParams;
  // console.log(currentUrl);

  if (!queryParams.get('tokenForCasTGT') && !queryParams.get('casTGT')) {
    // props.history.push('/feed/posts');
  }

  if (props.isAuth) {
    // props.history.push('/feed/posts');
  }

  let body;
  if (props.isLoading) {
    body = (<div className="notPageFound__Loader">
      {/* <Loader /> */}
      </div>);
  } 
  if (queryParams.get('tokenForCasTGT') && !props.isAuth) {
    body = (<div className="notPageFound__Loader">
      {/* <Loader /> */}
    </div>);
  }
  else {
    body = ( 
    <div className="notPageFound__container">
      {/* <GroupTopElements />
      <GroupRightElements /> */}
      
      {props.isAuth ?
          <div>
            <div className="notPageFound__textLink">
              {/* Where do you go today? */}
              {/* {t('notFound.text4')} */}
            </div>
            {/* <Link to="/feed/posts">
              go to feed page
              {t('notFound.text2')}
            </Link> */}

          {/* <div className="notPageFound__pageButton">
            <Link to="/feed/posts" className="notPageFound__linkButton">
              <Button
                    mode="raised" type="submit" design="success"
                    // disabled={!props.replyInput || props.commentLoading}
              >
                Feed Page
              </Button>
            </Link>
          </div> */}

          {/* <div className="notPageFound__pageButton">
            <Link to="/talk-page" className="notPageFound__linkButton">
              <Button
                    mode="raised" type="submit" design="success"
                    // disabled={!props.replyInput || props.commentLoading}
              >
                Talk Page
              </Button>
            </Link>
          </div> */}

          {/* <div className="notPageFound__pageButton">
            <Link to="/group-talk-page" className="notPageFound__linkButton">
              <Button
                    mode="raised" type="submit" design="success"
                    // disabled={!props.replyInput || props.commentLoading}
              >
                Group Page
              </Button>
            </Link>
          </div> */}
            {/* <Redirect to="/feed/posts" /> */}
          </div>
        :
          <div className="notPageFound">
              <a className="notPageFound__linkButton" href={loginPageLink} >
                <Button
                      mode="raised" type="submit" design="success"
                      // disabled={!props.replyInput || props.commentLoading}
                >
                  {/* Login */}
                  {t('general.text11')}
                </Button>
              </a>

            {/* <a className="notPageFound__linkButton" href={authSignupPageLink} >
              <Button
                    mode="raised" type="submit" design="success"
                    // disabled={!props.replyInput || props.commentLoading}
              >
                Signup
                {t('general.text12')}
              </Button>
            </a> */}

            {/* <div className="notPageFound__pageButton">
              <Link to="/feed/posts" className="notPageFound__linkButton">
                <Button
                      mode="raised" type="submit" design="success"
                      // disabled={!props.replyInput || props.commentLoading}
                >
                  Feed Page
                </Button>
              </Link>
            </div> */}

            {/* <div className="notPageFound__pageButton">
              <Link to="/group-talk-page" className="notPageFound__linkButton">
                <Button
                      mode="raised" type="submit" design="success"
                      // disabled={!props.replyInput || props.commentLoading}
                >
                  Group Page
                </Button>
              </Link>
            </div> */}

            {/* <div className="notPageFound__pageButton">
              <Link to="/talk-page" className="notPageFound__linkButton">
                <Button
                      mode="raised" type="submit" design="success"
                      // disabled={!props.replyInput || props.commentLoading}
                >
                  Talk Page
                </Button>
              </Link>
            </div> */}
            {/* <Redirect to="/login" /> */}
        </div>
      }
    </div>
    );
  }

  return (
    <Fragment>
      {!userData && (
        <LoginRequired />
      )}

      {userData && (
        <div className='notPageFound'>
          <h3>User page for {userData.email}</h3>
          <div>
            <Link to={`/userinfo${window.location.search}`}>
              <button>Go to user info setting page</button>
            </Link>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default NotPageFound;