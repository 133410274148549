import React, { Component, Fragment, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { withI18n } from "react-i18next";
import { I18nextProvider } from "react-i18next";
// import jwt from 'jsonwebtoken';

import i18n from "./i18n";

import Backdrop from "./components/Backdrop/Backdrop";
import Layout from "./components/Layout/Layout";
import Loader from "./components/Loader/Loader";

import LanguageList from "./translation/LanguageList";

// import ImageUpload from './pages/ImageUpload/ImageUpload';
import TermsOfUse from "./pages/Terms/TermsOfUse";
import PrivacyPolicy from "./pages/Terms/PrivacyPolicy";
import UserInfo from "./pages/UserInfo/UserInfo";
// import NeedToLogin from './pages/NeedToLogin/NeedToLogin';
import NotPageFound from "./pages/NotPageFound/NotPageFound";
// import DarkModeToggle from './components/DarkModeToggle/DarkModeToggle';

import AuthCheck from "./components/Auth/AuthCheck";
import GetAuth from "./components/Auth/GetAuth";
import SetUserData from "./components/Auth/SetUserData";
import UserSocketConnect from "./components/SocketConnect/UserSocketConnect";

import { useStore } from "./hook-store/store";
import { updateEmailVerified, updateUserInfo, getAuthInfo } from "./util/user";

import "./App.css";

const lsUuid = localStorage.getItem("lsUuid")
  ? localStorage.getItem("lsUuid")
  : "";

//// dev urls
// export const BASE_URL = process.env.REACT_APP_DEV_BASE_URL;
// export const GQL_URL = process.env.REACT_APP_DEV_GQL_URL;
// export const SOCKET_URL = process.env.REACT_APP_DEV_SOCKET_URL;
// export const SOCKET_GROUP_URL = process.env.REACT_APP_DEV_SOCKET_GROUP_URL;
// export const SOCKET_SURL = process.env.REACT_APP_DEV_SOCKET_SURL;
// export const SOCKET_GROUP_SURL = process.env.REACT_APP_DEV_SOCKET_GROUP_SURL;
// export const PUSH_URL = process.env.REACT_APP_DEV_PUSH_URL;
// export const ADNETWORK_URL = process.env.REACT_APP_DEV_ADNETWORK_URL;
// export const BACKEND_URL = process.env.REACT_APP_DEV_BACKEND_URL;
// export const SUBSCRIPTION_BACKEND_URL = BACKEND_URL;
// export const loginPageLink =
//   process.env.REACT_APP_DEV_AUTHPAGE_URL +
//   `/login?fromUrl=${encodeURIComponent(
//     window.location.origin
//   )}&lsUuid=${lsUuid}`;
// export const signupPageLink =
//   process.env.REACT_APP_DEV_AUTHPAGE_URL +
//   `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;


//// test deploy urls
// export const BASE_URL = process.env.REACT_APP_TEST_BASE_URL;
// export const GQL_URL = process.env.REACT_APP_GQL_URL;
// export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
// export const SOCKET_GROUP_URL = process.env.REACT_APP_SOCKET_GROUP_URL;
// export const SOCKET_SURL = process.env.REACT_APP_SOCKET_SURL;
// export const SOCKET_GROUP_SURL = process.env.REACT_APP_SOCKET_GROUP_SURL;
// export const PUSH_URL = process.env.REACT_APP_PUSH_URL;
// export const ADNETWORK_URL = process.env.REACT_APP_ADNETWORK_URL
// export const BACKEND_URL = process.env.REACT_APP_TEST_BACKEND_URL
// export const SUBSCRIPTION_BACKEND_URL = BACKEND_URL;
// export const loginPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/login?fromUrl=${encodeURIComponent(window.location.origin)}&lsUuid=${lsUuid}`;
// export const signupPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;


//// do urls deploy  Don't Forget update servicewoker file for build
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const GQL_URL = process.env.REACT_APP_GQL_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const SOCKET_GROUP_URL = process.env.REACT_APP_SOCKET_GROUP_URL;
export const SOCKET_SURL = process.env.REACT_APP_SOCKET_SURL;
export const SOCKET_GROUP_SURL = process.env.REACT_APP_SOCKET_GROUP_SURL;
export const PUSH_URL = process.env.REACT_APP_PUSH_URL;
export const ADNETWORK_URL = process.env.REACT_APP_ADNETWORK_URL
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
export const SUBSCRIPTION_BACKEND_URL = BACKEND_URL;
export const loginPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/login?fromUrl=${encodeURIComponent(window.location.origin)}&lsUuid=${lsUuid}`;
export const signupPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;



//// PWA-cource

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/service-worker.js')
    // .register('/sw.js')
    .then(() => {
      console.log('Service-worker registered!');
    }).catch((err) => {

      console.log('service-worker register error', err);
    });
}

//// end of PWA-course

// const aps = new AppStorage();
// console.log('appStorage', aps);

function App(props) {
  const [store, dispatch] = useStore();
  const { isAuth, gLoading } = store.uiStore;

  useEffect(() => {
    console.log("app.js-props", props);
    // this.getAuthInfo();

    window.addEventListener("beforeinstallprompt", (event) => {
      console.log("beforeinstallprompt fired in app");
      // event.preventDefault();
      // deferredPrompt = event;
      // return false;
    });

    //// set language if user selected language in localStorage
    const lsUserSelectLng = localStorage.getItem("userSelectLng");
    if (lsUserSelectLng) {
      // console.log(lsUserSelectLng);
      i18n.changeLanguage(lsUserSelectLng);
    }
  }, []);


  let routes;

  if (isAuth) {
    routes = (
      <Routes>
        <Route path="/userinfo" 
          element={<UserInfo />} 
        />

        <Route path="/"
          element={<NotPageFound />}
        />
        <Route path="*" 
          element={<NotPageFound />} 
        />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route path="*" 
          element={<NotPageFound />} 
        />
      </Routes>
    )
  }

  return (
    <Fragment>
      <I18nextProvider i18n={i18n}>
        {/* {(gLoading || srvLoading || metaLoading) && ( */}
        {gLoading && (
          <div>
            <Backdrop
              zIndex={"500"}
              onCancel={() => {
                if (!gLoading) {
                  // setClickedObjectKeyHandler('');
                }
              }}
            />
            <span className="gLoader">
              <Loader />
            </span>
          </div>
        )}

        <div>
          <Layout>
            <div>{routes}</div>
          </Layout>

            <div style={{ display: "none" }}>

              {/* set languageNameList in store */}
              <div style={{ display: "none" }}>
                <LanguageList />
              </div>

              <GetAuth />
              <AuthCheck />

              {isAuth && (
                <div>
                  <SetUserData />
                  <UserSocketConnect />
                </div>
              )}
            </div>

        </div>
      </I18nextProvider>
    </Fragment>
  );
}

export default App;
// export default App;
