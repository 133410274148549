import axios from 'axios';

export const getUserNameData = async (url, token) => {
  try {
    const result = await fetch(url + `/user-name-data`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      // body: JSON.stringify({})
    })

    if (!result.ok) {
      throw new Error('error occured');
    }

    const resData = await result.json();

    return resData;

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const updateUserNameDataName = async (url, token, newName) => {
  try {
    const result = await fetch(url + `/user-name-data/user-name`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        newName: newName,
      })
    })

    if (!result.ok) {
      throw new Error('error occured');
    }

    const resData = await result.json();

    return resData;

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const updateUserNameDataImage = async (url, token, selectedFiles) => {
  try {
    const data = new FormData(); 

    // console.log(selectedFiles);
    for (const file of selectedFiles) {
      data.append('images', file);
    }
    // data.append('files', selectedFiles);

    // console.log('data', data, data.getAll('files'));
    const headers = {
      // 'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    };
  
    // axios.post(url + "/bucket/object", data, { 
    const result = await axios.put(
      url + "/user-name-data/user-image", 
      data, 
      {  
        headers: headers
        // receive two    parameter endpoint url ,form data
    })

    if (result.status !== 200) {
      throw new Error('error occured')
    }

    return result;

  } catch(err) {
    console.log(err);
    throw err;
  }
};
 

export const deleteUserNameDataImage = async (url, token) => {
  try {
    const result = await fetch(url + `/user-name-data/user-image`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      // body: JSON.stringify({
      //   newName: newName,
      // })
    })

    if (!result.ok) {
      throw new Error('error occured');
    }

    const resData = await result.json();

    return resData;

  } catch(err) {
    console.log(err);
    throw err;
  }
};