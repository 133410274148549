import React, { Fragment, useEffect } from 'react';

// import openSocket from 'socket.io-client';
// import { io } from "socket.io-client";
import { io } from 'socket.io-client';

import { useStore } from '../../hook-store/store';
// import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util'
// import { getPrefixFromKey } from '../../../utils/bucket/bucket-object-util';

import { BACKEND_URL } from '../../App';

// import classes from './PostSelect.module.css';

function UserSocketConnect(props) {
  // const { } = props;

  const [store, dispatch] = useStore();
  // const { bucketName, currentPrefix } = store.bucketObjectStore;
  const { isAuth } = store.uiStore;

  useEffect(() => {
      // const socket = openSocket(BACKEND_URL);
      const socket = io(BACKEND_URL, {
        // autoConnect: false
      });

      // const userId = localStorage.getItem('userId');

      console.log('before socket.on connect')
      socket.on("connect", () => {
        console.log('connect socket.id', socket.id);
        // dispatch('SET_SOCKETID', socket.id);

        socket.emit('user-id', {
          socketId: socket.id,
          // userId: userId,
          token: localStorage.getItem('token'),
        });
      });

      // socket.on('casauthsso-user-name-data-update', (data) => {
      //   console.log('casauthsso-user-name-data-update data', data);
      // });


  },[]);


  // const getPrefixAllObjects = async (bucket, prefix) => {
  //   try {
  //     // setIsLoading(true);

  //     const resData = await bucketObjectUtils.getAllBucketObjects(
  //       BACKEND_URL,
  //       localStorage.getItem('token'),
  //       bucket,
  //       prefix,
  //     );
  //     // const result = await fetch(BACKEND_URL + `/bucket/all-objects?bucket=${bucket}&prefix=${prefix}`);
  //     console.log(resData);

  //     // dispatch('SET_OBJECTACTION', {
  //     //   actionType: 'tools-file-update',
  //     //   delete: true,
  //     //   add: true,
  //     //   result: null,
  //     // });

      
  //     // setIsLoading(false);
  //     return resData.data.objectList;
  //   } catch(err) {
  //     console.log(err);
  //     // setIsLoading(false);
  //   }
  // };



 

  return (
    <Fragment></Fragment>
  );
}

export default UserSocketConnect;