// import React from 'react';
import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next/hooks';
import { useStore } from '../../../../hook-store/store';

import { appInstallHandler } from '../../../../util/ui/ui-util';
import { logoutHandler } from '../../../../util/user/user';
import { marks } from '../../../../util/marks';

import { 
  loginPageLink, 
  signupPageLink,
  // drawDreamerLink,
 } from '../../../../App';

import './ToggleNavItems.css';


const ToggleNavItems = props => {
  // console.log('ToggleNavItems-props', props);

const [t] = useTranslation('translation');

const navigate = useNavigate();
// {t('termsOfUse.text1', 'terms of use')}

  const [store, dispatch] = useStore();
  const { 
    isAuth, 
    deferredPrompt, 
  }  = store.uiStore;

  const navItems = [
    { 
      id: 'home', 
      // text: 'feed',
      text: `Home`, 
      link: `/${window.location.search}`, 
      auth: false
    },
    { 
      id: 'userinfo', 
      // text: 'feed',
      text: `${t('nav.02', 'User Info')}`, 
      link: `/userinfo${window.location.search}`, 
      auth: true
    },
    { 
      id: 'login', 
      // text: 'feed',
      text: `${t('nav.03', 'Login')}`, 
      link: '/feed/posts', 
      auth: false
    },
    // { 
    //   id: 'signup', 
    //   // text: 'feed',
    //   text: `${t('nav.04', 'Signup')}`, 
    //   link: '/feed/posts', 
    //   auth: false
    // },
    // { 
    //   id: 'install-app', 
    //   // text: 'feed',
    //   text: `${t('nav.05', 'Install App')}`, 
    //   link: '/', 
    //   auth: false
    // },
    { 
      id: 'logout', 
      // text: 'feed',
      text: `${t('nav.06', 'Logout')}`, 
      link: '/', 
      auth: true
    },
  ];


  const logoutConfirm = () => {
    if (window.confirm(`${t('nav.07', "Is it no problem to logout?")}`)) {
      console.log('confirm true');
      // props.onLogout();

      dispatch('SET_ISAUTH', false);
      logoutHandler();
      navigate('/');
      window.location.reload();
    }
  }

  let toggleNavItemsBody;

  toggleNavItemsBody = (
    <ul>
      {navItems.map(item => {

        if (item.id === 'home') {
          return (
            <div className="toggleNavItem">
              <Link to={item.link} 
                onClick={() => {
                  dispatch('SET_SHOWTOGGLENAV', false);
                }}
              >
                <span className='toggleNavItemIcon'>
                  {marks.homeMark}
                </span> 
                <span className='toggleNavItemText'>
                  {item.text}
                </span>
              </Link>
            </div>
          );
        }

        if (!isAuth && item.id === 'login') {
          return (
            <div className="toggleNavItem">
              <span>
                <a href={loginPageLink} >
                  <span className='toggleNavItemIcon'>
                    {marks.signInMrak}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </a>
              </span>
            </div>
          );
        }

        if (!isAuth && item.id === 'signup') {
          return (
            <div className="toggleNavItem">
              <span>
                <a href={signupPageLink} >
                <span className='toggleNavItemIcon'>
                  {marks.userPlusMrak}
                </span> 
                <span className='toggleNavItemText'>
                  {item.text}
                </span>
                </a>
              </span>
            </div>
          );
        }


        if (isAuth && item.id === 'userinfo') {
          return (
              <div className="toggleNavItem">
                <Link to={item.link} 
                  onClick={() => {
                    dispatch('SET_SHOWTOGGLENAV', false);
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {marks.userMrak} 
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
          );
        }

        if (deferredPrompt && item.id === 'install-app') {
          return (
            <div className="toggleNavItem">
              <Link to={item.link} 
                onClick={() => {
                  appInstallHandler(deferredPrompt);
                  // dispatch('SET_SHOWTOGGLENAV', false);
                }}
              >
                <span className='toggleNavItemIcon'>
                  {marks.plusSquareOMark} 
                </span> 
                <span className='toggleNavItemText'>
                  {item.text}
                </span>
              </Link>
            </div>
          );          
        }

        if (isAuth && item.id === 'logout') {
          return (
              <div className="toggleNavItem">
                <span
                  onClick={() => { logoutConfirm(); }} 
                >
                <span className='toggleNavItemIcon'>
                  {marks.signOutMrak} 
                </span> 
                <span className='toggleNavItemText'>
                  {item.text}
                </span>
                </span>
              </div>
          );
        }

        else {
          return null;
        }

        // return (
        //   <div  className="toggleNavItem">
        //     <span>
        //     <span className='toggleNavItemIcon'>
        //       {item.text}
        //       {/* {marks.userMrak}  */}
        //       </span> 
        //     </span>
        //   </div>
        // );
      })}
    </ul>
  );

  return (
  <Fragment>
    {toggleNavItemsBody}
  </Fragment>
  );

  }
export default ToggleNavItems;
