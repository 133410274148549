import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import Backdrop from '../../Backdrop/Backdrop';
// import NavUser from '../NavUser/NavUser';
import ToggleNavButton from '../../Navigation/ToggleNav/ToggleNavButton/ToggleNavButton';
import ToggleNav from '../../Navigation/ToggleNav/ToggleNav/ToggleNav';

import { useStore } from '../../../hook-store/store';

// import { marks } from '../../../utils/marks';
import classes from './MainNav.module.css';

// import ObjectSearch from '../../BucketComponents/ObjectSearch/ObjectSearch';

function MainNav(props) {
  // console.log('Layout.js-props', props);
  const location = useLocation();

  const [store, dispatch] = useStore();
  // console.log('store- Layout.js', store);
  const { 
    showToggleNav, 
    gLoading,
    isAuth,
   } = store.uiStore;


  let isObjectsPage = false;

  if (location.pathname === '/') {
    isObjectsPage = true;
  }

  return (
    <Fragment>
      <div id='mainnav-el' 
        className={classes.mainNav}
      >
        <ToggleNav />
        <ToggleNavButton /> 
        {showToggleNav && (
          <Backdrop 
            zIndex={'190'}
            // backgroundColor={'rgba(0, 0, 0, 0.1)'}
          //  backgroundColor={'rgba(0, 0, 0, 0)'}
            onClick={() => { 
              if (!gLoading) {
                dispatch('SET_SHOWTOGGLENAV', false); 
              }
            }}
          />
        )}

        {window.innerWidth > 480 && (
          <div className={classes.mainNavTitle}>
            
          </div>
        )}
        
        {/* {isObjectsPage && (
          <ObjectSearch 
            setSearchSelectedItem={() => {}}
          />
        )} */}

        {/* {isAuth && (
          <NavUser />
        )} */}
        {!isAuth && (
          <div></div>
        )}
   
      </div>
    </Fragment>
  );
}

export default MainNav;
