import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next/hooks';
import i18n from '../../i18n';
import Input from '../../components/Form/Input/Input';
import Button from '../../components/Button/Button';
import DarkModeToggle from '../../components/DarkModeToggle/DarkModeToggle';
import UserInfoImageUpdate from './UserInfoImageUpdate';
import Loader from '../../components/Loader/Loader';
import LanguageList from '../../translation/LanguageList';
import { resources } from '../../translation/translation';
import SmallModal from '../../components/Modal/SmallModal';
import TransBackdrop from '../../components/Backdrop/TransBackdrop';

import DisableTfa from '../../components/TwoFactorAuth/DisableTfa';
import TwoFactorAuth from '../../components/TwoFactorAuth/TwoFactorAuth';
import {
  getUserLocation,
} from '../../util/user';
import { useStore } from '../../hook-store/store';
import { 
  updateUserNameDataName,
  updateUserNameDataImage,
} from '../../util/user-name-data/user-name-data-util';

import { BACKEND_URL} from '../../App'

import './UserInfoNameUpdate.css';
// import classes from './UserInfo.module.css';

const UserInfoNameUpdate = props => {
  // console.log('userinfo-props', props);

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { 
    userData, 
    userNameData 
  } = store.uiStore;
  // console.log('store UserInfo.js', store);

  const [nameInput, setNameInput] = useState(userNameData?.name);
  const [isNameUpdate, setIsNameUpdate] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  // const [selectedLanguageName, setSelectedLanguageName] = useState('');
  const [error, setError] = useState('');



  const nameInputChangeHandler = (input, value) => {
    setNameInput(value);
  }


  const updateUserNameHandler = (name) => {
    setIsLoading(true);
    updateUserNameDataName(
      BACKEND_URL, 
      localStorage.getItem('token'), 
      name
    )
      .then(result => {
        console.log(result);

        if (result.data) {
          dispatch('SET_USERNAMEDATA', result.data);
        }
        // setUserName(result.data.data.user.name);
        // localStorage.setItem('name', result.data.data.user.name);

        setIsNameUpdate(false);
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsNameUpdate(false);
        setIsLoading(false);
        // catchError(err);
      })
  }


  let userNameBody;

  if (userNameData) {
    userNameBody = (
      <div>
        <div>
          {/* <div className={classes.userInfoProfileImage}>{userNameData.name.slice(0,1)}</div> */}
          <span>
            {t('userInfo.text2', 'name')}:
          </span>
          {' '}
          <strong>{userNameData.name}</strong>
          {' '}
          <Button mode="flat" type="submit"
            onClick={() => { setIsNameUpdate(!isNameUpdate) }}
          >
            ({t('general.text5', '(edit)')})
          </Button>
        </div>
        {isNameUpdate && (
          <section>
            <div>
              <label>name input (max 50 characters)</label>
            </div>
            <Input
              type="text"
              placeholder="Name, less than 50 characters..."
              // placeholder={t('userInfo.text6')}
              control="input"
              onChange={nameInputChangeHandler}
              value={nameInput}
            />
            <div className="userInfo__actions">
    
              <Button
                mode="raised" type="submit"
                disabled={!nameInput || !nameInput.trim() || nameInput.length > 50}
                onClick={() => { updateUserNameHandler(nameInput) }}
              >
                {t('general.text6', 'Update')}
              </Button>
            </div>
          </section>
        )}
      </div>
    );
  }



  return (
    <Fragment>
        <div>
          {userNameBody}
          {isLoading && (
            <Loader />
          )}
        </div>
    </Fragment>
  );
}

export default UserInfoNameUpdate;